import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
	Outlet,
	RouterProvider,
	createRootRoute,
	createRoute,
	createRouter,
	useNavigate,
} from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { AppLayout } from "./AppLayout";
import { IndexPage } from "./IndexPage";
import { MenuViewPage } from "./MenuViewPage";
import { queryClient } from "./client";
import "antd/dist/reset.css";
import { useStore } from "@nanostores/react";
import { App } from "antd";
import { type ReactNode, useEffect, useState } from "react";
import { MenuEditPage } from "./MenuEditPage";
import { SettingsPage } from "./SettingsPage";
import { authStore } from "./authStore";

const rootRoute = createRootRoute({
	component: () => {
		const [showDevtools, setShowDevtools] = useState(false);
		useEffect(() => {
			Object.assign(window, {
				toggleDevtools: () => setShowDevtools((old) => !old),
			});
		}, []);
		return (
			<QueryClientProvider client={queryClient}>
				<App>
					<Outlet />
				</App>
				{showDevtools && (
					<>
						<TanStackRouterDevtools />
						<ReactQueryDevtools />
					</>
				)}
			</QueryClientProvider>
		);
	},
});

const authGuarded = (component: () => ReactNode) => {
	return () => {
		const auth = useStore(authStore);
		if (auth) return component();
		const navigate = useNavigate();
		navigate({ to: "/" });
	};
};

const appLayoutRoute = createRoute({
	getParentRoute: () => rootRoute,
	id: "app-layout",
	component: AppLayout,
});
const indexRoute = createRoute({
	getParentRoute: () => appLayoutRoute,
	path: "/",
	component: () => {
		const auth = useStore(authStore);
		if (auth) return <MenuEditPage />;
		return <IndexPage />;
	},
});
const menuRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/$restaurantId",
	component: MenuViewPage,
});
const settingsRoute = createRoute({
	getParentRoute: () => appLayoutRoute,
	path: "/settings",
	component: authGuarded(SettingsPage),
});

const routeTree = rootRoute.addChildren([
	appLayoutRoute,
	indexRoute,
	menuRoute,
	settingsRoute,
]);
const router = createRouter({ routeTree });
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}
export const AppRouter = () => <RouterProvider router={router} />;
