import { Skeleton } from "antd";
import dayjs from "dayjs";
import { forwardRef } from "react";
import { MenuView } from "./MenuView";
import { useQueryRestaurant } from "./client";

export const MenuPreview = forwardRef<HTMLDivElement>((_, ref) => {
	const data = useQueryRestaurant({});
	if (data.isLoading || !data.data) return <Skeleton active />;
	return (
		<div
			ref={ref}
			style={{
				position: "relative",
				width: "350px",
				height: "700px",
				border: "16px solid black",
				borderRadius: "36px",
				boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
				backgroundColor: "#f0f0f0",
			}}
		>
			{/* Smartphone top (hour, battery level and connectivity) */}
			<div
				style={{
					position: "absolute",
					top: 0,
					left: "50%",
					transform: "translateX(-50%)",
					width: "101%",
					height: "30px",
					backgroundColor: "black",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					color: "white",
					padding: "0 10px",
					fontFamily: "Arial, sans-serif",
					fontSize: "14px",
				}}
			>
				{/* Time */}
				<div>{dayjs().format("HH:mm")}</div>
				{/* Battery level and connectovity */}
				<div>
					<span style={{ marginRight: "10px" }}>🔋 75%</span>
					<span>📶 5G</span>
				</div>
			</div>
			{/* Camera */}
			<div
				style={{
					position: "absolute",
					top: "10px",
					left: "50%",
					transform: "translateX(-50%)",
					width: "10px",
					height: "10px",
					backgroundColor: "grey",
					borderRadius: "50%",
					boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
				}}
			/>
			{/* Screen display */}

			<div
				style={{
					position: "absolute",
					top: "30px",
					left: 0,
					right: 0,
					bottom: 0,
					margin: "0 auto",
					borderBottomLeftRadius: "25px",
					borderBottomRightRadius: "25px",
					backgroundColor: "white",
					overflow: "hidden",
				}}
			>
				<div style={{ zoom: 0.8 }}>
					<MenuView data={data.data} />
				</div>
			</div>
		</div>
	);
});
