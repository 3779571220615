import {
	DeleteOutlined,
	DownloadOutlined,
	InfoCircleOutlined,
	QrcodeOutlined,
	SmallDashOutlined,
	UpOutlined,
	WifiOutlined,
} from "@ant-design/icons";
import { useStore } from "@nanostores/react";
import { useNavigate } from "@tanstack/react-router";
import {
	Avatar,
	Button,
	Collapse,
	type CollapseProps,
	ColorPicker,
	Dropdown,
	Flex,
	Form,
	Input,
	List,
	type MenuProps,
	Select,
	Tag,
	Tooltip,
	Typography,
	message,
} from "antd";
import { Col, Row } from "antd";
import { QRCode } from "antd";
import { type CSSProperties, type ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiLanguage } from "react-icons/hi2";
import { IoShareSocialOutline } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import { useTitle } from "react-use";
import { authStore } from "./authStore";
const { Text } = Typography;

const currency = [
	{ currency_name: "Euro", symbol: "€", code: "EUR" },
	{ currency_name: "United States dollar", symbol: "$", code: "USD" },
	{ currency_name: "Australian dollar", symbol: "$", code: "AUD" },
	{ currency_name: "Sterling", symbol: "£", code: "GBP" },
	{ currency_name: "Eastern Caribbean dollar", symbol: "EC$", code: "XCD" },
	{ currency_name: "West African CFA franc", symbol: "Fr, F.CFA", code: "XOF" },
	{ currency_name: "New Zealand dollar", symbol: "$", code: "NZD" },
	{ currency_name: "Norwegian krone", symbol: "kr", code: "NOK" },
	{
		currency_name: "Central African CFA franc",
		symbol: "Fr, F.CFA",
		code: "XAF",
	},
	{ currency_name: "South African rand", symbol: "R", code: "ZAR" },
	{ currency_name: "CFP franc", symbol: "₣, F, or Fr", code: "XPF" },
	{ currency_name: "Chilean peso", symbol: "$", code: "CLP" },
	{ currency_name: "Danish krone", symbol: "kr", code: "DKK" },
	{ currency_name: "Indian rupee", symbol: "₹", code: "INR" },
	{ currency_name: "Russian rouble", symbol: "₽", code: "RUB" },
	{ currency_name: "Turkish lira", symbol: "₺", code: "TRY" },
	{ currency_name: "Algerian dinar", symbol: "DA", code: "DZD" },
	{ currency_name: "Mauritanian ouguiya", symbol: "UM", code: "MRU" },
	{ currency_name: "Moroccan dirham", symbol: "DH", code: "MAD" },
	{ currency_name: "Israeli new shekel", symbol: "₪", code: "ILS" },
	{ currency_name: "Jordanian dinar", symbol: "د.أ", code: "JOD" },
	{ currency_name: "Brunei dollar", symbol: "B$", code: "BND" },
	{ currency_name: "Singapore dollar", symbol: "$, S$", code: "SGD" },
	{ currency_name: "Hong Kong dollar", symbol: "$, HK$ or 元", code: "HKD" },
	{ currency_name: "Swiss franc", symbol: "Fr", code: "CHF" },
	{
		currency_name: "Netherlands Antillean guilder",
		symbol: "ƒ, NAƒ, NAf, or f",
		code: "ANG",
	},
	{ currency_name: "Saint Helena pound", symbol: "£", code: "SHP" },
	{ currency_name: "Falkland Islands pound", symbol: "£", code: "FKP" },
	{ currency_name: "Canadian Dollar", symbol: "$", code: "CAD" },
];

const countryCode = [
	"+1",
	"+1-242",
	"+1-246",
	"+1-264",
	"+1-268",
	"+1-284",
	"+1-340",
	"+1-345",
	"+1-441",
	"+1-473",
	"+1-649",
	"+1-664",
	"+1-670",
	"+1-671",
	"+1-684",
	"+1-721",
	"+1-758",
	"+1-767",
	"+1-784",
	"+1-787",
	"+1-809",
	"+1-868",
	"+1-869",
	"+1-876",
	"+20",
	"+211",
	"+212",
	"+212",
	"+213",
	"+216",
	"+218",
	"+220",
	"+221",
	"+222",
	"+223",
	"+224",
	"+225",
	"+226",
	"+227",
	"+228",
	"+229",
	"+230",
	"+231",
	"+232",
	"+233",
	"+234",
	"+235",
	"+236",
	"+237",
	"+238",
	"+239",
	"+240",
	"+241",
	"+242",
	"+243",
	"+244",
	"+246",
	"+248",
	"+249",
	"+250",
	"+251",
	"+252",
	"+253",
	"+254",
	"+255",
	"+256",
	"+257",
	"+258",
	"+260",
	"+261",
	"+262",
	"+262",
	"+263",
	"+264",
	"+265",
	"+266",
	"+267",
	"+268",
	"+269",
	"+27",
	"+290",
	"+291",
	"+297",
	"+298",
	"+299",
	"+30",
	"+31",
	"+32",
	"+33",
	"+34",
	"+350",
	"+351",
	"+352",
	"+353",
	"+354",
	"+355",
	"+356",
	"+357",
	"+358",
	"+359",
	"+36",
	"+370",
	"+371",
	"+372",
	"+373",
	"+374",
	"+375",
	"+376",
	"+377",
	"+378",
	"+379",
	"+380",
	"+381",
	"+382",
	"+383",
	"+385",
	"+386",
	"+387",
	"+389",
	"+39",
	"+40",
	"+41",
	"+420",
	"+421",
	"+423",
	"+43",
	"+44",
	"+44-1481",
	"+44-1534",
	"+44-1624",
	"+45",
	"+46",
	"+47",
	"+47",
	"+48",
	"+49",
	"+500",
	"+501",
	"+502",
	"+503",
	"+504",
	"+505",
	"+506",
	"+507",
	"+508",
	"+509",
	"+51",
	"+52",
	"+53",
	"+54",
	"+55",
	"+56",
	"+57",
	"+58",
	"+590",
	"+590",
	"+591",
	"+592",
	"+593",
	"+595",
	"+597",
	"+598",
	"+599",
	"+599",
	"+60",
	"+61",
	"+61",
	"+61",
	"+62",
	"+63",
	"+64",
	"+64",
	"+65",
	"+66",
	"+670",
	"+672",
	"+673",
	"+674",
	"+675",
	"+676",
	"+677",
	"+678",
	"+679",
	"+680",
	"+681",
	"+682",
	"+683",
	"+685",
	"+686",
	"+687",
	"+688",
	"+689",
	"+690",
	"+691",
	"+692",
	"+7",
	"+7",
	"+81",
	"+82",
	"+84",
	"+850",
	"+852",
	"+853",
	"+855",
	"+856",
	"+86",
	"+880",
	"+886",
	"+90",
	"+91",
	"+92",
	"+93",
	"+94",
	"+95",
	"+960",
	"+961",
	"+962",
	"+963",
	"+964",
	"+965",
	"+966",
	"+967",
	"+968",
	"+970",
	"+971",
	"+972",
	"+973",
	"+974",
	"+975",
	"+976",
	"+977",
	"+98",
	"+992",
	"+993",
	"+994",
	"+995",
	"+996",
	"+998",
];

const Menulanguages = [
	{ lang: "French", isDefault: true },
	{ lang: "English" },
];

const handleMenuClick: MenuProps["onClick"] = (e) => {
	message.info("Click on menu item.");
	console.log("click", e);
};

const items: MenuProps["items"] = [
	{
		label: "Choose as main",
		key: "1",
		icon: <HiLanguage />,
	},
	{
		label: "Sort Up/Down",
		key: "2",
		icon: <UpOutlined />,
	},
	{
		label: "Remove",
		key: "3",
		icon: <DeleteOutlined />,
		danger: true,
	},
];

const menuProps = {
	items,
	onClick: handleMenuClick,
};

const MenuLanguages = () => {
	return (
		<List
			size="small"
			footer={<Button type="link">Add language</Button>}
			dataSource={Menulanguages}
			renderItem={(item) => (
				<List.Item>
					<Flex
						justify="space-between"
						align="center"
						style={{ width: "100%" }}
					>
						{item.lang}
						{item.isDefault ? <Tag>Main</Tag> : ""}
						<Dropdown menu={menuProps}>
							<Button shape="circle" icon={<SmallDashOutlined />} />
						</Dropdown>
					</Flex>
				</List.Item>
			)}
		/>
	);
};

export const SettingsPage = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	useTitle(t("DetailsPage.title"));
	const auth = useStore(authStore);
	const [qrCodeColor, setQrCodeColor] = useState("#000");

	function doDownload(url: string, fileName: string) {
		const a = document.createElement("a");
		a.download = fileName;
		a.href = url;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	const downloadSvgQRCode = () => {
		const svg = document
			.getElementById("qrcode")
			?.querySelector<SVGElement>("svg");
		if (!svg) throw new Error("QRCode not found");
		const svgData = new XMLSerializer().serializeToString(svg);
		const blob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
		const url = URL.createObjectURL(blob);

		doDownload(url, "qr-restaurant-com.svg");
	};

	const getItems: (panelStyle: CSSProperties) => CollapseProps["items"] = (
		panelStyle,
	) => [
		{
			key: "1",
			label: collapseHeader(
				<InfoCircleOutlined />,
				"Basic information",
				"All the details about your establishment.",
			),
			children: (
				<Form
					labelCol={{ span: 4 }}
					wrapperCol={{ span: 20 }}
					layout="horizontal"
				>
					<Form.Item label="Language">
						<Select
							defaultValue={i18n.resolvedLanguage}
							style={{ width: "100%" }}
							optionLabelProp="label"
							options={[
								{ value: "en", label: t("DetailsPage.english") },
								{ value: "fr", label: t("DetailsPage.french") },
							]}
							onChange={(x) => i18n.changeLanguage(x)}
						/>
					</Form.Item>

					<Form.Item label="Establishment name">
						<Input />
					</Form.Item>
					<Form.Item label="Public Menu URL">
						<Input
							addonBefore={`${location.host}/`}
							suffix={
								<Tooltip
									title={
										Math.random() > 0
											? "c'est ok url dispo"
											: "déso c déjà pris bro"
									}
								>
									{Math.random() > 0 ? (
										<InfoCircleOutlined style={{ color: "rgba(0,255,0,1)" }} />
									) : (
										<InfoCircleOutlined style={{ color: "rgba(255,0,0,1)" }} />
									)}
								</Tooltip>
							}
						/>
					</Form.Item>
					<Form.Item label="Address">
						<Input placeholder="Enter a location" />
					</Form.Item>
					<Form.Item label="Phone Number">
						<Row gutter={8}>
							<Col span={6}>
								<Select showSearch>
									{countryCode.map((item) => (
										<Select.Option key={item} value={item}>
											{item}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col span={18}>
								<Input />
							</Col>
						</Row>
					</Form.Item>
					<Form.Item label="Currency">
						<Select showSearch>
							{currency.map((item) => (
								<Select.Option key={item.code} value={item.code}>
									{item.currency_name} ({item.symbol})
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
					</Form.Item>
				</Form>
			),
			style: panelStyle,
		},
		{
			key: "2",
			label: collapseHeader(
				<TbWorld />,
				"Menu languages",
				"Present your menus in additional languages.",
			),
			children: MenuLanguages(),
			style: panelStyle,
			// collapsible: "disabled", // if one wants to turn off a panel, that's how.
		},
		{
			key: "3",
			label: collapseHeader(
				<IoShareSocialOutline />,
				"Social networks",
				"show your customers the links to your most popular social networks.",
			),
			children: (
				<Form
					labelCol={{ span: 4 }}
					wrapperCol={{ span: 20 }}
					layout="horizontal"
				>
					<Form.Item label="Instagram">
						<Input addonBefore="@" />
					</Form.Item>
					<Form.Item label="Facebook">
						<Input addonBefore="@" />
					</Form.Item>
					<Form.Item label="X (Twitter)">
						<Input addonBefore="@" />
					</Form.Item>
					<Form.Item label="Youtube">
						<Input addonBefore="@" />
					</Form.Item>
				</Form>
			),
			style: panelStyle,
		},
		{
			key: "4",
			label: collapseHeader(
				<WifiOutlined />,
				"Wi-Fi",
				"Share your network and password name so that your customers connect to your Wi-Fi.",
			),
			children: (
				<Form
					labelCol={{ span: 4 }}
					wrapperCol={{ span: 20 }}
					layout="horizontal"
				>
					<Form.Item label="Network name (SSID)">
						<Input />
					</Form.Item>
					<Form.Item label="Password">
						<Input />
					</Form.Item>
				</Form>
			),
			style: panelStyle,
		},
	];

	const collapseHeader = (
		icon: ReactNode,
		title: string,
		description: string,
	) => {
		return (
			<Row align="middle">
				<Col span={4}>
					<Avatar
						style={{ backgroundColor: "#F5F5F5", color: "#000" }}
						shape="square"
						size={{ md: 40, lg: 64, xl: 80 }}
						icon={icon}
					/>
				</Col>
				<Col span={20}>
					<Flex vertical>
						<Typography.Title level={4}>{title}</Typography.Title>
						<Text type="secondary">{description}</Text>
					</Flex>
				</Col>
			</Row>
		);
	};

	const panelStyle: React.CSSProperties = {
		marginBottom: 24,
		background: "#fff",
		borderRadius: "8px",
		border: "none",
		boxShadow: "0 .3px .9px rgba(0,0,0,.08),0 1.6px 3.6px rgba(0,0,0,.08)",
	};

	return (
		<Row gutter={[16, 16]} justify="space-evenly">
			<Col
				xs={{ flex: "100%" }}
				md={{ flex: "75%" }}
				style={{
					border: "2px solid #C7C7C7",
					borderRadius: "5px",
					padding: 20,
				}}
			>
				<h2>{t("DetailsPage.settings")}</h2>
				<Collapse
					style={{ backgroundColor: "transparent", border: "none" }}
					size="small"
					items={getItems(panelStyle)}
					expandIconPosition="end"
				/>
			</Col>

			<Col
				xs={{ flex: "100%" }}
				md={{ flex: "20%" }}
				style={{
					border: "2px solid #C7C7C7",
					borderRadius: "5px",
					padding: 20,
				}}
			>
				<h2>QR Code</h2>
				<center>
					<QRCode
						id="qrcode"
						bgColor="#fff"
						value={`${location.origin}/${auth?.id}`}
						type="svg"
						color={qrCodeColor}
					/>
				</center>
				<br />
				<Flex gap="small" vertical>
					<Button type="primary" icon={<QrcodeOutlined />}>
						{t("DetailsPage.buyQrCodeStickers")}
					</Button>
					<Button icon={<DownloadOutlined />} onClick={downloadSvgQRCode}>
						{t("DetailsPage.download")}
					</Button>
					<ColorPicker
						defaultValue={qrCodeColor}
						onChange={(color) => setQrCodeColor(color.toHexString())}
						showText={(color) => (
							<span>
								{t("DetailsPage.QrCodeColorText")} ({color.toHexString()})
							</span>
						)}
					/>
				</Flex>
			</Col>
			<Button
				onClick={() => {
					authStore.set(null);
					navigate({ to: "/" });
				}}
			>
				{t("IndexPage.logout")}
			</Button>
		</Row>
	);
};
