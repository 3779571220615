import { ExportOutlined } from "@ant-design/icons";
import { useStore } from "@nanostores/react";
import { Outlet, useLocation, useNavigate } from "@tanstack/react-router";
import { Layout, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { authStore } from "./authStore";

export const AppLayout = () => {
	const pathname = useLocation({ select: (x) => x.pathname });
	const navigate = useNavigate();
	const auth = useStore(authStore);
	const { t } = useTranslation();
	return (
		<Layout style={{ minHeight: "100vh" }}>
			<Layout.Header style={{ display: "flex", alignItems: "center" }}>
				<div className="demo-logo" />
				<Menu
					style={{ flex: "auto", minWidth: 0 }}
					theme="dark"
					mode="horizontal"
					selectedKeys={[pathname]}
					items={[
						{
							key: "/",
							label: t("AppLayout.menu.home"),
							onClick: () => navigate({ to: "/" }),
						},
						{
							key: "/settings",
							label: t("AppLayout.menu.settings"),
							onClick: () => navigate({ to: "/settings" }),
							disabled: auth == null,
						},
						{
							key: `/${auth?.id}`,
							label: (
								<>
									{t("AppLayout.menu.myMenu")} <ExportOutlined />
								</>
							),
							onClick: () => window.open(`/${auth?.id}`, "_blank")?.focus(),
							disabled: auth == null,
						},
					]}
				/>
			</Layout.Header>
			<Layout.Content style={{ padding: 24 }}>
				<Outlet />
			</Layout.Content>
			<Layout.Footer style={{ textAlign: "center" }}>
				{t("AppLayout.footer")}
			</Layout.Footer>
		</Layout>
	);
};
