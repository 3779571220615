import { Avatar, Badge, Card } from "antd";

type MenuViewItemProps = {
	lang: string;
	item: {
		id: string;
		name: { [key: string]: { value: string } };
		description: { [key: string]: { value: string } };
		picture: string;
		price: number;
	};
	currency: string;
};
export const MenuViewItem = ({ item, lang, currency }: MenuViewItemProps) => {
	const price = Intl.NumberFormat(lang, { style: "currency", currency }).format(
		item.price,
	);
	return (
		<Badge.Ribbon key={item.id} text={price} color="green">
			<Card>
				<Card.Meta
					title={item.name[lang]?.value}
					description={item.description[lang]?.value}
					avatar={
						item.picture && (
							<Avatar shape="square" size={64} src={item.picture} />
						)
					}
				/>
			</Card>
		</Badge.Ribbon>
	);
};
