import { Card, Flex } from "antd";
import { useTranslation } from "react-i18next";
import { useTitle } from "react-use";
import { LoginForm } from "./LoginForm";
import { useMutationLogin } from "./client";

export const IndexPage = () => {
	const { t } = useTranslation();
	useTitle(t("IndexPage.title"));
	const login = useMutationLogin();
	return (
		<Flex justify="center">
			<Card title={t("IndexPage.login")} style={{ width: "300px" }}>
				<LoginForm onFinish={async (x) => login.mutateAsync(x)} />
			</Card>
		</Flex>
	);
};
