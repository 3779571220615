import { HolderOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type { ReactNode } from "@tanstack/react-router";
import {
	Button,
	ConfigProvider,
	type ConfigProviderProps,
	Flex,
	Table,
	type TableColumnsType,
} from "antd";
import type { AnyObject } from "antd/es/_util/type";
import type { ColumnType } from "antd/es/table";
import {
	type CSSProperties,
	type ComponentProps,
	type HTMLAttributes,
	createContext,
	useContext,
	useMemo,
} from "react";
import { useSearch } from "./useSearch";

type CoolTableProps<T extends AnyObject> = ComponentProps<typeof Table<T>> &
	Pick<ConfigProviderProps, "renderEmpty"> & {
		searcheable?: boolean;
		ellipsis?: boolean;
		rightActions?: ReactNode;
		rowActions?: CoolTableColumnType<T>[number];
		onDragEnd?: (active: string, over: string) => void;
	};

type CoolColumnType<T> = Omit<ColumnType<T>, "dataIndex"> & {
	dataIndex: Extract<keyof T, string>;
};

export type CoolTableColumnType<T> = CoolColumnType<T>[];

type RowContextProps = {
	setActivatorNodeRef?: (element: HTMLElement | null) => void;
	listeners?: SyntheticListenerMap;
};
const RowContext = createContext<RowContextProps>({});
const DragHandle = () => {
	const { setActivatorNodeRef, listeners } = useContext(RowContext);
	return (
		<Button
			type="text"
			size="small"
			icon={<HolderOutlined />}
			style={{ cursor: "move", touchAction: "none" }}
			ref={setActivatorNodeRef}
			{...listeners}
		/>
	);
};
type RowProps = HTMLAttributes<HTMLTableRowElement> & {
	"data-row-key": string;
};

const Row = (props: RowProps) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: props["data-row-key"] });

	const style: CSSProperties = {
		...props.style,
		transform: CSS.Translate.toString(transform),
		transition,
		...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
	};

	const contextValue = useMemo<RowContextProps>(
		() => ({ setActivatorNodeRef, listeners }),
		[setActivatorNodeRef, listeners],
	);

	return (
		<RowContext.Provider value={contextValue}>
			<tr {...props} ref={setNodeRef} style={style} {...attributes} />
		</RowContext.Provider>
	);
};
const dragColumn: TableColumnsType = [
	{
		key: "sort",
		align: "center",
		width: 40,
		render: () => <DragHandle />,
	},
];

export const CoolTable = <T extends AnyObject>(props: CoolTableProps<T>) => {
	const { filteredData, searchElement } = useSearch(
		props.dataSource,
		props.searcheable,
	);
	const allColumns = useMemo(() => {
		return [
			...(props.onDragEnd ? dragColumn : []),
			...(props.columns ?? []).map((x) => {
				x.ellipsis = props.ellipsis ?? true;
				return x;
			}),
			...(props.rowActions ? [props.rowActions] : []),
		];
	}, [props.rowActions, props.columns, props.ellipsis, props.onDragEnd]);
	const handleDragEnd = ({ active, over }: DragEndEvent) => {
		if (active.id === over?.id) return;
		if (typeof active.id !== "string" || typeof over?.id !== "string") return;
		props.onDragEnd?.(active.id, over.id);
	};

	return (
		<Flex gap={16} vertical>
			<Flex gap={16}>
				{searchElement}
				{props.rightActions}
			</Flex>
			<DndContext
				modifiers={[restrictToVerticalAxis]}
				onDragEnd={handleDragEnd}
			>
				<SortableContext
					items={filteredData.map((i) => i.id)}
					strategy={verticalListSortingStrategy}
				>
					<ConfigProvider renderEmpty={props.renderEmpty}>
						<Table
							{...props}
							pagination={false}
							columns={allColumns}
							components={{ body: { row: Row } }}
							dataSource={filteredData}
							rowKey={props.rowKey ?? "id"}
							size={props.size ?? "small"}
							bordered={props.bordered ?? true}
						/>
					</ConfigProvider>
				</SortableContext>
			</DndContext>
		</Flex>
	);
};
