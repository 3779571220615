import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { type InferType, object, string } from "yup";
import { FormItem } from "./FormItem";

const useSchema = () => {
	const { t } = useTranslation();
	const schema = object({
		email: string().required().label(t("global.email")),
		password: string().required().label(t("global.password")),
	});
	return schema;
};
type LoginFormItem = InferType<ReturnType<typeof useSchema>>;

type LoginFormProps = { onFinish: (x: LoginFormItem) => Promise<unknown> };
export const LoginForm = ({ onFinish }: LoginFormProps) => {
	const schema = useSchema();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [form] = Form.useForm<LoginFormItem>();
	const handleFinish = async (values: LoginFormItem) => {
		setIsLoading(true);
		await onFinish(values).finally(() => setIsLoading(false));
	};
	return (
		<Form
			form={form}
			onFinish={handleFinish}
			layout="vertical"
			requiredMark={false}
		>
			<FormItem schema={schema} name="email">
				<Input
					prefix={<UserOutlined />}
					autoCapitalize="off"
					autoCorrect="off"
				/>
			</FormItem>
			<FormItem schema={schema} name="password">
				<Input prefix={<LockOutlined />} type="password" />
			</FormItem>
			<Flex justify="center">
				<Button type="primary" htmlType="submit" loading={isLoading}>
					{t("LoginForm.login")}
				</Button>
			</Flex>
		</Form>
	);
};
