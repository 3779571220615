import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { enLocale, frLocale } from "./locales";

const defaultNS = "translation";
const resources = {
	en: { translation: enLocale },
	fr: { translation: frLocale },
} as const;

declare module "i18next" {
	interface CustomTypeOptions {
		defaultNS: typeof defaultNS;
		resources: (typeof resources)["en"];
	}
}

export const initI18n = () =>
	i18next
		.use(LanguageDetector)
		.use(initReactI18next)
		.init({
			fallbackLng: "en",
			defaultNS,
			resources,
			interpolation: { escapeValue: false },
			detection: { caches: ["cookie"] },
			load: "languageOnly",
		});
