import { AlignRightOutlined } from "@ant-design/icons";
import { Button, Divider, Drawer, Flex, Layout, List, Typography } from "antd";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuViewItem } from "./MenuViewItem";
import type { RestaurantItem } from "./client";

export const MenuView = ({ data }: { data: RestaurantItem }) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const contentEl = useRef<HTMLDivElement>(null);
	const lang = data.langs[0] ?? "en";
	const currency = data.currency ?? "GBP";
	const scrollToCategory = (cat: string) => {
		setOpen(false);
		const el = document.getElementById(cat);
		if (!el || !contentEl.current) return;
		const offset = contentEl.current.getBoundingClientRect().y;
		const elOffset = el.getBoundingClientRect().top;
		const top = elOffset + contentEl.current.scrollTop - offset;
		contentEl.current.scrollTo({ top, behavior: "smooth" });
	};
	return (
		<Layout
			style={{ position: "relative", height: "100%", maxHeight: "100vh" }}
		>
			<Drawer
				title={t("TopNavBar.pickCategory")}
				onClose={() => setOpen(false)}
				open={open}
				getContainer={false}
			>
				<List
					size="small"
					bordered
					dataSource={data.menus.flatMap((x) => x.categories)}
					renderItem={(item) => (
						<List.Item
							onClick={() => scrollToCategory(item.name[lang]?.value ?? "")}
						>
							<Typography.Title
								style={{ margin: 0, cursor: "pointer" }}
								level={3}
							>
								{item.name[lang]?.value}
							</Typography.Title>
						</List.Item>
					)}
				/>
			</Drawer>
			<Layout.Header
				onClick={() => setOpen(true)}
				style={{ alignContent: "center", cursor: "pointer" }}
			>
				<Flex justify="space-between">
					<Typography.Title style={{ margin: 0, color: "white" }} level={3}>
						{data.name}
					</Typography.Title>
					<Button ghost icon={<AlignRightOutlined />} />
				</Flex>
			</Layout.Header>
			<Layout.Content
				ref={contentEl}
				style={{
					overflow: "auto",
					scrollbarWidth: "none",
					padding: 24,
				}}
			>
				{data.menus
					.filter((x) => x.available)
					.flatMap((x) => x.categories)
					.filter((x) => x.available)
					.map((category) => (
						<Typography.Paragraph key={category.id}>
							<Divider orientation="left">
								<Typography.Title
									level={2}
									id={category.name[lang]?.value}
									style={{ margin: 0 }}
								>
									{category.name[lang]?.value}
								</Typography.Title>
							</Divider>
							<Flex vertical gap="middle">
								{category.items
									.filter((x) => x.available)
									.map((x) => (
										<MenuViewItem
											key={x.id}
											item={x}
											lang={lang}
											currency={currency}
										/>
									))}
							</Flex>
						</Typography.Paragraph>
					))}
			</Layout.Content>
		</Layout>
	);
};
