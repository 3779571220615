import { useParams } from "@tanstack/react-router";
import { Skeleton } from "antd";
import { useTitle } from "react-use";
import { MenuView } from "./MenuView";
import { useQueryRestaurant } from "./client";

export const MenuViewPage = () => {
	const { restaurantId } = useParams({ from: "/$restaurantId" });
	const data = useQueryRestaurant({ id: restaurantId }, 5000);
	useTitle(data.data?.name ?? "");
	if (data.isLoading || !data.data) return <Skeleton active />;
	return <MenuView data={data.data} />;
};
