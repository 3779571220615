import type { AnyObject } from "antd/es/_util/type";
import Search from "antd/es/transfer/search";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export const useSearch = <T extends AnyObject>(
	dataSource: readonly T[] = [],
	searcheable = false,
) => {
	const { t } = useTranslation();
	const [search, setSearch] = useState("");
	const searchWords = useMemo(() => {
		if (!searcheable) return [];
		return search
			.split(" ")
			.filter((x) => x)
			.map((x) => x.toLowerCase());
	}, [search, searcheable]);
	const filteredData = useMemo(() => {
		if (!searcheable) return dataSource;
		return dataSource?.filter((x) =>
			searchWords.every((word) =>
				Object.values(x).some((value) =>
					value?.toString().toLowerCase().includes(word),
				),
			),
		);
	}, [dataSource, searchWords, searcheable]);
	const searchElement = searcheable ? (
		<Search
			placeholder={t("global.search")}
			value={search}
			onChange={(x) => setSearch(x.target.value)}
		/>
	) : null;
	return { filteredData, searchElement };
};
