import {
	DeleteOutlined,
	EyeInvisibleOutlined,
	EyeOutlined,
} from "@ant-design/icons";
import {
	Avatar,
	Button,
	Popconfirm,
	Select,
	Space,
	Tooltip,
	Typography,
} from "antd";
import { type ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CoolTable, type CoolTableColumnType } from "./CoolTable";
import {
	type RestaurantItem,
	useMutationInsert,
	useMutationItemDelete,
	useMutationItemUploadPicture,
	useMutationItemUpsert,
} from "./client";
import CookingPot from "./cooking-pot.svg";

type MenuEditTableProps = {
	category: RestaurantItem["menus"][0]["categories"][0];
	lang: string;
	currency: string;
	catOptions: { label: string; value: string }[];
};

export const MenuEditTable = ({
	category,
	lang,
	currency,
	catOptions,
}: MenuEditTableProps) => {
	const { t } = useTranslation();
	const updateItem = useMutationItemUpsert();
	const columns = useColumns(catOptions, lang, currency);
	const insert = useMutationInsert();
	const removeItem = useMutationItemDelete();

	const renderEmpty = () => (
		<div style={{ textAlign: "center" }}>
			<Avatar size="large" src={CookingPot} />
			<p>{t("MenuEditPage.emptyData")}</p>
		</div>
	);

	return (
		<CoolTable
			renderEmpty={renderEmpty}
			showHeader={false}
			bordered={false}
			columns={columns}
			dataSource={[...category.items]}
			ellipsis
			onDragEnd={(a, b) => insert.mutate({ a, b, type: "item" })}
			rowActions={{
				dataIndex: "id",
				render: (_, item) => (
					<Space>
						<Tooltip
							title={
								item.available
									? t("MenuEditPage.hideItem")
									: t("MenuEditPage.showItem")
							}
						>
							<Button
								shape="circle"
								size="small"
								onClick={() =>
									updateItem.mutate({ ...item, available: !item.available })
								}
								icon={
									item.available ? <EyeOutlined /> : <EyeInvisibleOutlined />
								}
							/>
						</Tooltip>
						<Popconfirm
							title={`${t("global.delete")}?`}
							onConfirm={() => removeItem.mutate(item.id)}
							okText={t("global.yes")}
							cancelText={t("global.no")}
						>
							<Button
								shape="circle"
								title={t("global.delete")}
								icon={<DeleteOutlined />}
								size="small"
								danger
							/>
						</Popconfirm>
					</Space>
				),
				width: 65,
			}}
		/>
	);
};

type PictureCellProps = {
	item: { id: string; picture: string };
	onChange: (id: string, file: File) => Promise<unknown>;
};
const PictureCell = ({ item, onChange }: PictureCellProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	return (
		<>
			<input
				type="file"
				aria-label="upload image"
				ref={inputRef}
				onChange={async (e) => {
					const file = e.target.files?.[0];
					if (file) {
						await onChange(item.id, file);
						if (!inputRef.current) return;
						inputRef.current.value = "";
					}
				}}
				style={{ display: "none" }}
			/>
			<Avatar
				shape="square"
				style={{ cursor: "pointer" }}
				size={32}
				src={item.picture}
				onClick={() => inputRef.current?.click()}
			/>
		</>
	);
};

type EditableProps = {
	onChange: (value: string) => void;
	children: ReactNode;
	text?: string;
};
const Editable = ({ onChange, children, text }: EditableProps) => {
	const { t } = useTranslation();

	return (
		<Typography.Text
			style={{ cursor: "pointer" }}
			editable={{
				tooltip: t("TestPage.clickToEdit"),
				triggerType: ["text"],
				onChange,
				text,
			}}
		>
			{children}
		</Typography.Text>
	);
};

const useColumns = (
	catOptions: { label: string; value: string }[],
	lang: string,
	currency: string,
) => {
	const updateItem = useMutationItemUpsert();
	const uploadItemPicture = useMutationItemUploadPicture();
	const { t } = useTranslation();
	const currencyFormatter = new Intl.NumberFormat(lang, {
		style: "currency",
		currency,
	});
	const columns: CoolTableColumnType<
		RestaurantItem["menus"][0]["categories"][0]["items"][0]
	> = [
		{
			title: t("columns.picture"),
			dataIndex: "picture",
			width: 70,
			responsive: ["xl", "xxl"],
			render: (_, item) => (
				<>
					<PictureCell
						item={item}
						onChange={(id, picture) =>
							uploadItemPicture.mutateAsync({ id, picture })
						}
					/>
					{item.picture && (
						<Popconfirm
							title={`${t("global.delete")}?`}
							onConfirm={() => updateItem.mutate({ ...item, picture: "" })}
							okText={t("global.yes")}
							cancelText={t("global.no")}
						>
							<Button
								type="link"
								shape="circle"
								size="small"
								style={{ zoom: 0.7 }}
								danger
								icon={<DeleteOutlined />}
							/>
						</Popconfirm>
					)}
				</>
			),
		},
		{
			title: t("columns.name"),
			dataIndex: "name",
			width: 200,
			render: (_, item) => (
				<Editable
					onChange={(name) =>
						name !== (item.name[lang]?.value ?? "") &&
						updateItem.mutate({ ...item, name: { [lang]: { value: name } } })
					}
				>
					{item.name[lang]?.value}
				</Editable>
			),
		},
		{
			title: t("columns.description"),
			dataIndex: "description",
			responsive: ["xl", "xxl"],
			render: (_, item) => (
				<Editable
					onChange={(description) =>
						description !== item.description[lang]?.value &&
						updateItem.mutate({
							...item,
							description: { en: { value: description } },
						})
					}
				>
					{item.description[lang]?.value}
				</Editable>
			),
		},
		{
			title: t("columns.price"),
			dataIndex: "price",
			width: 100,
			render: (_, item) => (
				<Editable
					onChange={(price) =>
						price !== `${item.price}` &&
						updateItem.mutate({ ...item, price: +price })
					}
					text={`${item.price}`}
				>
					{currencyFormatter.format(item.price)}
				</Editable>
			),
		},
		{
			title: t("columns.category"),
			dataIndex: "categoryId",
			responsive: ["xl", "xxl"],
			width: 150,
			render: (_, item) => (
				<Select
					value={item.categoryId}
					style={{ width: "100%" }}
					options={catOptions}
					onChange={(categoryId) => updateItem.mutate({ ...item, categoryId })}
				/>
			),
		},
	];
	return columns;
};
