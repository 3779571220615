export const enLocale = {
	global: {
		edit: "Edit",
		delete: "Delete",
		update: "Update",
		reset: "Reset",
		submit: "Submit",
		yes: "Yes",
		no: "No",
		create: "Create",
		search: "Search",
		failedLoading: "Failed to load data",
		email: "Email",
		password: "Password",
	},
	AppLayout: {
		menu: {
			home: "Home",
			category: "Category",
			items: "Items",
			myMenu: "Preview",
			settings: "Settings",
		},
		footer: "qr-restaurant.com",
	},
	CategoryPage: {
		title: "Category",
	},
	client: {
		login: "Logging in",
		logout: "Logging out",
		upsertCategory: "Saving category",
		upsertItem: "Saving item",
		deleteCategory: "Deleting category",
		deleteItem: "Deleting item",
		swapCategory: "Swapping categories",
		swapItem: "Swapping items",
		updateRestaurantName: "Updating restaurant name",
		uploadItemPicture: "Uploading item picture",
		insertCategory: "Inserting category",
		upsertRestaurant: "Saving restaurant",
	},
	columns: {
		name: "Name",
		description: "Description",
		price: "Price",
		picture: "Picture",
		available: "Available",
		category: "Category",
	},
	DetailsPage: {
		title: "Details",
		restaurantName: "Restaurant's name ",
		language: "Language",
		english: "English",
		french: "French",
		settings: "Settings",
		qrCode: "QR Code",
		buyQrCodeStickers: "Buy QR Code's sticker",
		download: "Download",
		QrCodeColorText: "QR Code color",
	},
	IndexPage: {
		title: "Home",
		welcome: "Welcome",
		login: "Login",
		logout: "Logout",
	},
	ItemPage: {
		title: "Items",
	},
	LoginForm: {
		login: "Log in",
	},
	TopNavBar: {
		pickCategory: "Pick a category",
	},
	TestPage: {
		clickToEdit: "Click to edit",
	},
	MenuEditPage: {
		newDish: "New dish",
		newCategory: "New category",
		emptyData: "Dishes in this category will appear here.",
		hideCategory: "Hide category from menu",
		showCategory: "Show category in menu",
		hideItem: "Hide dish from menu",
		showItem: "Show dish in menu",
	},
};

export const frLocale: typeof enLocale = {
	global: {
		edit: "Modifier",
		delete: "Supprimer",
		update: "Mettre à jour",
		reset: "Réinitialiser",
		submit: "Valider",
		yes: "Oui",
		no: "Non",
		create: "Créer",
		search: "Rechercher",
		failedLoading: "Erreur lors du chargement des données",
		email: "Email",
		password: "Mot de passe",
	},
	AppLayout: {
		menu: {
			home: "Accueil",
			category: "Catégorie",
			items: "Élément",
			myMenu: "Aperçu",
			settings: "Paramètres",
		},
		footer: "qr-restaurant.com",
	},
	CategoryPage: {
		title: "Catégorie",
	},
	client: {
		login: "Connexion",
		logout: "Déconnexion",
		upsertCategory: "Sauvegarde de la catégorie",
		upsertItem: "Sauvegarde de l'élément",
		deleteCategory: "Suppression de la catégorie",
		deleteItem: "Suppression de l'élément",
		swapCategory: "Échange de catégories",
		swapItem: "Échange d'éléments",
		updateRestaurantName: "Mise à jour du nom du restaurant",
		uploadItemPicture: "Téléchargement de l'image de l'élément",
		insertCategory: "Insertion de la catégorie",
		upsertRestaurant: "Sauvegarde du restaurant",
	},
	columns: {
		name: "Nom",
		description: "Description",
		price: "Prix",
		picture: "Image",
		available: "Disponible",
		category: "Catégorie",
	},
	DetailsPage: {
		title: "Détails",
		restaurantName: "Nom du restaurant",
		language: "Langue",
		english: "Anglais",
		french: "Français",
		settings: "Paramètres",
		qrCode: "QR Code",
		buyQrCodeStickers: "Acheter les stickers de mon QR Code",
		download: "Télécharger",
		QrCodeColorText: "Couleur du QR Code",
	},
	IndexPage: {
		title: "Accueil",
		welcome: "Bienvenue",
		login: "Connexion",
		logout: "Déconnexion",
	},
	ItemPage: {
		title: "Éléments",
	},
	LoginForm: {
		login: "Connexion",
	},
	TopNavBar: {
		pickCategory: "Choisissez une catégorie",
	},
	TestPage: {
		clickToEdit: "Cliquez pour modifier",
	},
	MenuEditPage: {
		newDish: "Nouveau plat",
		newCategory: "Nouvelle catégorie",
		emptyData: "Les plats de cette catégorie seront ajouté ici.",
		hideCategory: "Cacher la catégorie du menu",
		showCategory: "Afficher la catégorie au menu",
		hideItem: "Cacher le plat du menu",
		showItem: "Afficher le plat au menu",
	},
};
