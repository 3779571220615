import { treaty } from "@elysiajs/eden";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryClient } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { App } from "antd";
import { useTranslation } from "react-i18next";
import type { Server } from "../../server/server";
import { authStore } from "./authStore";

const persister = createSyncStoragePersister({ storage: localStorage });
export const queryClient = new QueryClient();
persistQueryClient({ queryClient, persister });

const domain = location.port
	? `${location.protocol}//${location.hostname}:3000`
	: location.hostname;
// @ts-expect-error
const client = treaty<Server>(domain, {
	fetcher: async (url, options) => {
		const newOptions = options ?? {};
		newOptions.headers ??= {};
		newOptions.signal = AbortSignal.timeout(3000);
		Object.assign(newOptions.headers, { Token: authStore.value?.token });
		const response = await fetch(url, newOptions);
		if (response.status >= 400) throw await response.clone().json();
		return response;
	},
});

// biome-ignore lint/suspicious/noExplicitAny: CBB
type ExtractType<T extends (...args: any) => any> = NonNullable<
	Awaited<ReturnType<T>>["data"]
>;

const useMessages = (action: string, deps: string[] = []) => {
	const { message } = App.useApp();
	return {
		onError: (x: Error) =>
			void message.error({
				content: `...${action}!? ${x.message}`,
				key: action,
			}),
		onMutate: () =>
			void message.open({
				type: "loading",
				content: `${action}...`,
				key: action,
			}),
		onSuccess: () => {
			message.success({
				content: `...${action}!`,
				key: action,
			});
			for (const dep of deps)
				queryClient.invalidateQueries({ queryKey: [dep] });
		},
	};
};

type LoginBody = Parameters<typeof client.api.auth.login.post>[0];
export type LoginData = ExtractType<typeof client.api.auth.login.post>;
export const useMutationLogin = () => {
	const { t } = useTranslation();
	return useMutation({
		mutationKey: ["auth", "login"],
		mutationFn: async (body: LoginBody) =>
			client.api.auth.login.post(body).then((x) => {
				authStore.set(x.data);
				return x.data;
			}),
		...useMessages(t("client.login")),
	});
};

type CategoryUpsertBody = Parameters<typeof client.api.categories.post>[0];
export const useMutationCategoryUpsert = () => {
	const { t } = useTranslation();
	return useMutation({
		mutationKey: ["categories"],
		mutationFn: (body: CategoryUpsertBody) =>
			client.api.categories.post(body).then((x) => x.data),
		...useMessages(t("client.upsertCategory"), ["restaurants"]),
	});
};

type ItemUpsertBody = Parameters<typeof client.api.items.post>[0];
export const useMutationItemUpsert = () => {
	const { t } = useTranslation();
	return useMutation({
		mutationKey: ["items"],
		mutationFn: (body: ItemUpsertBody) =>
			client.api.items.post(body).then((x) => x.data),
		...useMessages(t("client.upsertItem"), ["restaurants"]),
	});
};

export const useMutationCategoryDelete = () => {
	const { t } = useTranslation();
	return useMutation({
		mutationKey: ["categories"],
		mutationFn: (id: string) =>
			client.api.categories.delete({ id }).then((x) => x.data),
		...useMessages(t("client.deleteCategory"), ["restaurants"]),
	});
};

export const useMutationItemDelete = () => {
	const { t } = useTranslation();
	return useMutation({
		mutationKey: ["items"],
		mutationFn: (id: string) =>
			client.api.items.delete({ id }).then((x) => x.data),
		...useMessages(t("client.deleteItem"), ["restaurants"]),
	});
};

type InsertBody = Parameters<typeof client.api.helpers.insert.post>[0];
export const useMutationInsert = () => {
	const { t } = useTranslation();
	return useMutation({
		mutationKey: ["categories", "swap"],
		mutationFn: (body: InsertBody) =>
			client.api.helpers.insert.post(body).then((x) => x.data),
		...useMessages(t("client.swapCategory"), ["restaurants"]),
	});
};

export type RestaurantItem = ExtractType<typeof client.api.restaurants.get>;
type RestaurantBody = Parameters<typeof client.api.restaurants.get>[0]["query"];
export const useQueryRestaurant = (
	query: RestaurantBody = {},
	refetchInterval: number | false = false,
) =>
	useQuery({
		queryKey: ["restaurants", query?.id],
		queryFn: () => client.api.restaurants.get({ query }).then((x) => x.data),
		refetchInterval,
		initialData: null,
	});

type ItemUploadPictureBody = Parameters<
	typeof client.api.items.picture.upload.post
>[0];
export const useMutationItemUploadPicture = () => {
	const { t } = useTranslation();
	return useMutation({
		mutationKey: ["items", "uploadPicture"],
		mutationFn: (body: ItemUploadPictureBody) =>
			client.api.items.picture.upload.post(body).then((x) => x.data),
		...useMessages(t("client.uploadItemPicture"), ["restaurants"]),
	});
};

type CategoryInsertBody = Parameters<
	typeof client.api.categories.insert.post
>[0];
export const useMutationCategoryInsert = () => {
	const { t } = useTranslation();
	return useMutation({
		mutationKey: ["categories", "insert"],
		mutationFn: (body: CategoryInsertBody) =>
			client.api.categories.insert.post(body).then((x) => x.data),
		...useMessages(t("client.insertCategory"), ["restaurants"]),
	});
};

type RestaurantUpsertBody = Parameters<typeof client.api.restaurants.post>[0];
export const useMutationRestaurantUpsert = () => {
	const { t } = useTranslation();
	return useMutation({
		mutationKey: ["restaurants"],
		mutationFn: (body: RestaurantUpsertBody) =>
			client.api.restaurants.post(body).then((x) => x.data),
		...useMessages(t("client.upsertRestaurant"), ["restaurants"]),
	});
};
