import {
	DeleteOutlined,
	DownOutlined,
	EyeInvisibleOutlined,
	EyeOutlined,
	MobileOutlined,
	PlusOutlined,
	UpOutlined,
} from "@ant-design/icons";
import {
	Affix,
	Button,
	Card,
	Divider,
	Flex,
	FloatButton,
	Popconfirm,
	Skeleton,
	Space,
	Tooltip,
	Typography,
} from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ImSpoonKnife } from "react-icons/im";
import { useTitle } from "react-use";
import { MenuEditTable } from "./MenuEditTable";
import { MenuPreview } from "./MenuPreview";
import {
	useMutationCategoryDelete,
	useMutationCategoryInsert,
	useMutationCategoryUpsert,
	useMutationInsert,
	useMutationItemUpsert,
	useMutationRestaurantUpsert,
	useQueryRestaurant,
} from "./client";

const useCategoryOptions = (categories: { id: string; name: string }[]) =>
	categories.map((category) => ({
		label: category.name,
		value: category.id,
	}));

export const MenuEditPage = () => {
	useTitle("Menu");
	const { t } = useTranslation();
	const data = useQueryRestaurant();
	const updateCategory = useMutationCategoryUpsert();
	const insert = useMutationInsert();
	const removeCategory = useMutationCategoryDelete();
	const updateItem = useMutationItemUpsert();
	const insertCategory = useMutationCategoryInsert();
	const upsertRestaurant = useMutationRestaurantUpsert();
	const id = data.data?.id;
	const lang = data.data?.langs?.[0] ?? "en";
	const currency = data.data?.currency ?? "GBP";
	const catOptions = useCategoryOptions(
		data.data?.menus
			?.flatMap((x) => x.categories)
			.map((x) => ({
				id: x.id,
				name: x.name[lang]?.value ?? "",
			})) ?? [],
	);
	const [showPreview, setShowPreview] = useState(screen.width > 1081);
	if (data.isLoading || !data.data || !id) return <Skeleton active />;
	const categories = data.data.menus.flatMap((x) => x.categories);
	if (!categories) return t("global.failedLoading");
	return (
		<Flex gap={24}>
			<FloatButton
				icon={<MobileOutlined />}
				type="primary"
				onClick={() => setShowPreview(!showPreview)}
			/>
			<FloatButton.BackTop style={{ insetInlineEnd: 24 * 3 }} />
			<div>
				<Divider>
					<Typography.Title
						style={{ margin: 0, cursor: "pointer" }}
						editable={{
							tooltip: "Click to edit",
							triggerType: ["text"],
							onChange: (name) =>
								upsertRestaurant.mutate({
									id,
									name,
								}),
						}}
					>
						{data.data.name}
					</Typography.Title>
				</Divider>
				{categories.map((category, index) => (
					<Card key={category.id} style={{ marginBottom: "10px" }}>
						<Card.Meta
							title={
								<Flex justify="space-between">
									<Typography.Title
										level={3}
										style={{ margin: 0, cursor: "pointer" }}
										editable={{
											tooltip: "Click to edit",
											triggerType: ["text"],
											onChange: (name) =>
												name !== category.name[lang]?.value &&
												updateCategory.mutate({
													...category,
													name: { [lang]: { value: name } },
												}),
										}}
									>
										{category.name[lang]?.value}
									</Typography.Title>
									<Space>
										<Button
											shape="circle"
											size="small"
											icon={<UpOutlined />}
											disabled={index === 0}
											onClick={() =>
												insert.mutate({
													a: category.id,
													b: categories[index - 1]?.id ?? "",
													type: "category",
												})
											}
										/>
										<Button
											shape="circle"
											size="small"
											icon={<DownOutlined />}
											disabled={index === categories.length - 1}
											onClick={() =>
												insert.mutate({
													a: category.id,
													b: categories[index + 1]?.id ?? "",
													type: "category",
												})
											}
										/>
										<Tooltip
											title={
												category.available
													? t("MenuEditPage.hideCategory")
													: t("MenuEditPage.showCategory")
											}
										>
											<Button
												shape="circle"
												size="small"
												onClick={() =>
													updateCategory.mutate({
														...category,
														available: !category.available,
													})
												}
												icon={
													category.available ? (
														<EyeOutlined />
													) : (
														<EyeInvisibleOutlined />
													)
												}
											/>
										</Tooltip>
										<Popconfirm
											title={`${t("global.delete")}?`}
											onConfirm={() => removeCategory.mutate(category.id)}
											okText={t("global.yes")}
											cancelText={t("global.no")}
										>
											<Button
												shape="circle"
												size="small"
												icon={<DeleteOutlined />}
												danger
											/>
										</Popconfirm>
									</Space>
								</Flex>
							}
							description={
								<>
									<MenuEditTable
										catOptions={catOptions}
										currency={currency}
										category={category}
										lang={data.data?.langs[0] ?? "en"}
									/>
									<Flex style={{ marginTop: 20 }} gap="middle" justify="end">
										<Button
											icon={<ImSpoonKnife />}
											onClick={() =>
												updateItem.mutate({
													name: { [lang]: { value: "..." } },
													description: { [lang]: { value: "..." } },
													price: 0,
													available: false,
													picture: "",
													categoryId: category.id,
												})
											}
											size="small"
										>
											{t("MenuEditPage.newDish")}
										</Button>
										<Button
											icon={<PlusOutlined />}
											onClick={() =>
												insertCategory.mutate({
													afterId: category.id,
												})
											}
											size="small"
										>
											{t("MenuEditPage.newCategory")}
										</Button>
									</Flex>
								</>
							}
						/>
					</Card>
				))}
			</div>
			{showPreview && (
				<Affix offsetTop={25}>
					<MenuPreview />
				</Affix>
			)}
		</Flex>
	);
};
